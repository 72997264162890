/* eslint-disable react/no-array-index-key */
import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

interface ProductsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Products
}

const BigContainer = styled.div`
  max-width: 1300px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`

const Products: React.FC<ProductsProps> = ({ fields }) => (
  <div className="py-lg-5 py-4">
    <BigContainer>
      <div className="row">
        {fields?.chosenproducts?.map((product, index: number) => (
          <ProductPreview key={index} index={index} product={product} />
        ))}
      </div>
    </BigContainer>
  </div>
)

export default Products

const StyledProductPreview = styled(motion.div)`
  background-color: ${(props) => props.theme.color.face.secondary};
  padding: 50px 40px 50px 40px;
  border-radius: 14px;
  height: 530px;

  @media screen and (max-width: 991px) {
    height: fit-content;
    padding-bottom: 80px;
  }

  @media screen and (max-width: 350px) {
    padding: 25px 15px 35px 15px;
  }
`

const Content = styled(ParseContent)`
  /* position: relative; */
  z-index: 10;
  height: 100%;

  & p:last-of-type {
    position: absolute;
    left: 40px;
    bottom: 40px;
  }

  @media screen and (max-width: 1200px) {
    li {
      font-size: ${(props) => props.theme.font.size[14]};
      line-height: 25px !important;
    }
  }

  @media screen and (max-width: 991px) {
    li {
      font-size: ${(props) => props.theme.font.size[16]};
      line-height: 30px;
    }
  }
`

const StyledImage = styled(Plaatjie)`
  position: absolute !important;
  height: 350px;
  width: 220px;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  pointer-events: none;

  & img {
    object-fit: contain !important;
  }

  @media screen and (max-width: 576px) {
    opacity: 0.3;
  }

  @media screen and (max-width: 350px) {
    display: none !important;
  }
`

interface ProductPreviewProps {
  // eslint-disable-next-line
  product: GatsbyTypes.Maybe<GatsbyTypes.WpProduct>
  index: number
}

const ProductPreview: React.FC<ProductPreviewProps> = ({ product, index }) => (
  <div
    className={`col-lg-6 mb-4 mb-lg-0 pb-lg-5 pb-4 ${
      index % 2 === 0 ? `pr-lg-4` : `pl-lg-4`
    }`}
  >
    <StyledProductPreview
      whileHover={{ boxShadow: '0 0 15px rgba(0,0,0,.3)' }}
      className="position-relative"
    >
      <Content content={product?.productPreview?.description} />
      <StyledImage
        loading="eager"
        image={product?.productPreview?.image}
        alt=""
      />
    </StyledProductPreview>
  </div>
)
